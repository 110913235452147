@media screen and (max-width: 800px) {
  .colection-deck-section {
    font-family: sans-serif;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .colection-area-cartas {
    padding: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 80%;
  }
  
  .colection-cartas {
    background-color: rgba(213, 224, 224, 0.644);
    border: 0.1ch solid rgba(46, 43, 43, 0.281);
    border-radius: 1.0em;
    font-family: sans-serif;
    height: 420px;
    margin: 0;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .colection-carta-super {
    background-color: rgba(213, 224, 224, 0.644);
    border: 0.1ch solid #f96565;
    border-radius: 1.0em;
    font-family: sans-serif;
    height: 420px;
    margin: 0;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .colection-textos-card {
    align-items: center;
    color: #4B4A4B;
    font-size: 17px;
    font-weight: 600;
    max-width: 300px;
    padding-left: 5px;
    text-align: center;
  }
  
  .colection-top-text {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 10%;
    color: #4B4A4B;
    font-size: 17px;
    width: 100%;
  }
  
  #colection-card-price {
    background-image: url('../Images/custo.png');
    background-size: cover;
    background-position: 50%;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: last baseline;
    width: 11%;
    color: #696869;
    font-weight: 600;
  }
  
  #colection-card-name {
    text-align: center;
    margin: 0 2px 0 2px;
    width: 70%;
  }
  
  #colection-rarity-card-Lenda {
    background-image: url('../Images/Lenda.png');
    background-size: cover;
    height: 30px;
    width: 30px;
  }
  
  #colection-rarity-card-Normal {
    background-image: url('../Images/Normal.png');
    background-size: cover;
    height: 30px;
    width: 30px;
  }
  
  #colection-rarity-card-Raro {
    background-image: url('../Images/Raro.png');
    background-size: cover;
    height: 30px;
    width: 30px;
  }
  
  
  #colection-img-attr-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
  }
  
  .colection-imagem {
    background-color: transparent;
    outline: none;
    border-radius: 1em;
  }
  
  .colection-attr-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  
  #colection-attr1-card,
  #colection-attr2-card,
  #colection-attr3-card {
    border-radius: 3em;
    border-style: none;
    border: #7D7C7C solid 0.2ch;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 25%;
    font-size: 15px;
    font-weight: 400;
    margin-top: 15px;
  }
  
  #colection-description {
    border-style: none;
    line-height: 15px;
    height: 85px;
    width: 85%;
    background-color: transparent;
    resize: none;
    display: flex;
    text-align: center;
    overflow-y: visible;
    font-size: 15px;
  }
  
  #colection-description-small {
    border-style: none;
    line-height: 15px;
    height: fit-content;
    width: 85%;
    background-color: transparent;
    resize: none;
    display: flex;
    text-align: center;
    overflow-y: visible;
    font-size: 15px;
  }
  
  #colection-description-area {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
  
  #colection-delete-button {
    font-size: 15px;
    height: 30px;
    width: 100%;
    border-style: none;
    background-color: #F96565;
    border-radius: 1ch;
    color: #4B4A4B;
    cursor: pointer;
  }
  
  #colection-delete-button:hover {
    color: #f5f0f5;
  }
  
  #trunfo-card {
    color: #f96565;
    font-weight: 600;
    font-size: 20px;
  }
  
  #logo-card {
    color: #4B4A4B;
    font-weight: 600;
    font-size: 18px;
  }
}

@media screen and (min-width: 800px) {
  .colection-deck-section {
    font-family: sans-serif;
    width: 100vw;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .colection-area-cartas {
    padding: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 90%;
  }
  
  .colection-cartas {
    background-color: rgba(213, 224, 224, 0.644);
    border: 0.1ch solid rgba(46, 43, 43, 0.281);
    border-radius: 1.0em;
    font-family: sans-serif;
    height: 420px;
    margin: 0;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .colection-carta-super {
    background-color: rgba(213, 224, 224, 0.644);
    border: 0.1ch solid #f96565;
    border-radius: 1.0em;
    font-family: sans-serif;
    height: 420px;
    margin: 0;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .colection-textos-card {
    align-items: center;
    color: #4B4A4B;
    font-size: 17px;
    font-weight: 600;
    max-width: 300px;
    padding-left: 5px;
    text-align: center;
  }
  
  .colection-top-text {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 10%;
    color: #4B4A4B;
    font-size: 17px;
    width: 100%;
  }
  
  #colection-card-price {
    background-image: url('../Images/custo.png');
    background-size: cover;
    background-position: 50%;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: last baseline;
    width: 11%;
    color: #696869;
    font-weight: 600;
  }
  
  #colection-card-name {
    text-align: center;
    margin: 0 2px 0 2px;
    width: 70%;
  }
  
  #colection-rarity-card-Lenda {
    background-image: url('../Images/Lenda.png');
    background-size: cover;
    height: 30px;
    width: 30px;
  }
  
  #colection-rarity-card-Normal {
    background-image: url('../Images/Normal.png');
    background-size: cover;
    height: 30px;
    width: 30px;
  }
  
  #colection-rarity-card-Raro {
    background-image: url('../Images/Raro.png');
    background-size: cover;
    height: 30px;
    width: 30px;
  }
  
  
  #colection-img-attr-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
  }
  
  .colection-imagem {
    background-color: transparent;
    outline: none;
    border-radius: 1em;
  }
  
  .colection-attr-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  
  #colection-attr1-card,
  #colection-attr2-card,
  #colection-attr3-card {
    border-radius: 3em;
    border-style: none;
    border: #7D7C7C solid 0.2ch;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 25%;
    font-size: 15px;
    font-weight: 400;
    margin-top: 15px;
  }
  
  #colection-description {
    border-style: none;
    line-height: 15px;
    height: 85px;
    width: 85%;
    background-color: transparent;
    resize: none;
    display: flex;
    text-align: center;
    overflow-y: visible;
    font-size: 15px;
  }
  
  #colection-description-small {
    border-style: none;
    line-height: 15px;
    height: fit-content;
    width: 85%;
    background-color: transparent;
    resize: none;
    display: flex;
    text-align: center;
    overflow-y: visible;
    font-size: 15px;
  }
  
  #colection-description-area {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
  
  #colection-delete-button {
    font-size: 15px;
    height: 30px;
    width: 100%;
    border-style: none;
    background-color: #F96565;
    border-radius: 1ch;
    color: #4B4A4B;
    cursor: pointer;
  }
  
  #colection-delete-button:hover {
    color: #f5f0f5;
  }
  
  #trunfo-card {
    color: #f96565;
    font-weight: 600;
    font-size: 20px;
  }
  
  #logo-card {
    color: #4B4A4B;
    font-weight: 600;
    font-size: 18px;
  }
}