@media screen and (max-width: 800px) {
  .creation-page {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 800px) {
  .creation-page {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    text-align: center;
    width: 100%;
    height: 100vh;
  }
}