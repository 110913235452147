@media screen and (max-width: 800px) {
  a {
    text-decoration: none;
    color: inherit;
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  #interation-area-plus {
    height: 180px;
  }

  #interation-area {
    display: flex;
    height: 180px;
    flex-direction: column;
    background-color: #fed8d8;
    border-radius: 1em;
    justify-content: space-around;
  }

  #deck-creation-input {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 60%;
    color: #4b4a4b;
  }

  #name-deck {
    border-radius: 0.8em;
    height: 30px;
    width: 290px;
    border: none;
    outline: none;
    text-align: center;
    border-bottom: solid 0.1cm #3fad49;
    font-size: 15px;
  }

  #name-deck-max {
    border-radius: 0.8em;
    height: 30px;
    width: 290px;
    border: none;
    outline: none;
    text-align: center;
    border-bottom: solid 0.1cm #f96565;
    color: red;
    font-size: 15px;
    animation: treme 0.1s;
    animation-iteration-count: 3;
  }

  @keyframes treme {
    0% {
      margin-left: 0;
    }

    25% {
      margin-left: 5px;
    }

    50% {
      margin-left: 0;
    }

    75% {
      margin-left: -5px;
    }

    100% {
      margin-left: 0;
    }
  }

  #btn-area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 130px;
  }

  #btn-area-plus {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 90px;
  }

  #interation-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 300px;
    height: 150px;
  }

  #btn-back,
  #btn-to-decks,
  #btn-open-input,
  #btn-create-deck-act,
  #btn-to-colection {
    background-color: #f96565;
    color: #ffff;
    border: none;
    border-radius: 1em;
    padding: 5px;
    font-size: 18px;
    width: 300px;
    height: 40px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
  }

  #btn-create-deck-act {
    background-color: #3fad49;
    font-size: 19px;
  }

  #btn-create-deck-dis:disabled {
    background-color: #f965655d;
    color: #ffff;
    border: none;
    border-radius: 1em;
    padding: 5px;
    font-size: 18px;
    width: 300px;
    height: 40px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
  }
}

@media screen and (min-width: 800px) {

  a {
    text-decoration: none;
    color: inherit;
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: relative;
  }

  #interation-area-plus {
    height: 180px;
  }

  #interation-area {
    display: flex;
    height: 180px;
    flex-direction: column;
    justify-content: space-around;
  }

  #deck-creation-input {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 60%;
    color: #4b4a4b;
  }

  #name-deck {
    border-radius: 0.8em;
    height: 30px;
    width: 290px;
    border: none;
    outline: none;
    text-align: center;
    border-bottom: solid 0.1cm #3fad49;
    font-size: 15px;
  }

  #name-deck-max {
    border-radius: 0.8em;
    height: 30px;
    width: 290px;
    border: none;
    outline: none;
    text-align: center;
    border-bottom: solid 0.1cm #f96565;
    color: red;
    font-size: 15px;
    animation: treme 0.1s;
    animation-iteration-count: 3;
  }

  @keyframes treme {
    0% {
      margin-left: 0;
    }

    25% {
      margin-left: 5px;
    }

    50% {
      margin-left: 0;
    }

    75% {
      margin-left: -5px;
    }

    100% {
      margin-left: 0;
    }
  }

  #btn-area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 130px;
  }

  #btn-area-plus {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 90px;
  }

  #interation-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 300px;
    height: 150px;
  }

  #btn-back,
  #btn-to-decks,
  #btn-open-input,
  #btn-create-deck-act,
  #btn-to-colection {
    background-color: #f96565;
    color: #ffff;
    border: none;
    border-radius: 1em;
    padding: 5px;
    font-size: 18px;
    width: 300px;
    height: 40px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
  }

  #btn-back:hover,
  #btn-open-input:hover,
  #btn-to-decks:hover,
  #btn-to-colection:hover {
    font-weight: 600;
    font-size: 19px;
    transform: scale(1.1);
    transition: 0.3s;
  }

  #btn-create-deck-dis:disabled {
    background-color: #f965655d;
    color: #ffff;
    border: none;
    border-radius: 1em;
    padding: 5px;
    font-size: 18px;
    width: 300px;
    height: 40px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
  }

  #btn-create-deck-act:hover {
    background-color: #3fad49;
    font-size: 19px;
    transform: scale(1.1);
    transition: 0.3s;
  }

}