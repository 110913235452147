@media screen and (max-width: 800px) {
  .secao-decks {
    background-color: #fed8d8;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 100vh;
  }

  #decks-list {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 90%;
  }

  #deck {
    width: 70%;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 800px) {
  .secao-decks {
    background-color: #fed8d8;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 100vh;
  }

  #decks-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 95%;
  }

  #deck {
    width: fit-content;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
}