@media screen and (min-width: 800px) {
  .search-area {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 46px;
    width: 50%;
    position: absolute;
    background-color: #F6E9EE;
    border-radius: 0.5em;
    margin-top: 14px;
  }

  .lbl {
    color: #F96565;
    font-size: 18px;
  }

  #input-name {
    border: none;
    border-radius: 0.5em;
    color: #4B4A4B;
    height: 25px;
    width: 150px;
    text-align: center;
  }

  #card-rarity {
    border: none;
    border-radius: 0.5em;
    color: #4B4A4B;
    height: 25px;
    width: 70px;
    text-align: center;
    outline: none;
  }

  #card-rarity option {
    color: #4B4A4B;
    height: 25px;
    width: 70px;
    text-align: center;
    outline: none;
  }
  
}

@media screen and (max-width: 400px) {
  .search-area {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 10%;
    width: 95%;
    position: absolute;
    background-color: #F6E9EE;
    border-radius: 0.5em;
    margin-top: 8px;
  }

  .lbl {
    color: #F96565;
    font-size: 18px;
  }

  #input-name {
    border: none;
    border-radius: 0.5em;
    color: #4B4A4B;
    height: 25px;
    width: 150px;
    text-align: center;
  }

  #card-rarity {
    border: none;
    border-radius: 0.5em;
    color: #4B4A4B;
    height: 25px;
    width: 70px;
    text-align: center;
    outline: none;
  }
}

@media screen and (max-width: 799px) {
  .search-area {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 10%;
    width: 50%;
    position: absolute;
    background-color: #F6E9EE;
    border-radius: 0.5em;
    margin-top: 8px;
  }

  .lbl {
    color: #F96565;
    font-size: 18px;
  }

  #input-name {
    border: none;
    border-radius: 0.5em;
    color: #4B4A4B;
    height: 25px;
    width: 150px;
    text-align: center;
  }

  #card-rarity {
    border: none;
    border-radius: 0.5em;
    color: #4B4A4B;
    height: 25px;
    width: 70px;
    text-align: center;
    outline: none;
  }
}