@media screen and (max-width: 800px) {
  .header {
    background-color: #F96565;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    width: 100%;    
  }
  #menu-header-title {
    /* Área do título do menu */
    display: flex;
    justify-content: center;
    color: #F6E9EE;
    width: 100%;
    position: absolute;
    z-index: 0;
  }

  #creation-header-title,
  #decks-header-title,
  #colection-header-title {
    padding-left: 10px;
  }

  #title {
    /* <p> com o texto do título */
    color: #F6E9EE;
    font-family: 'Broken Dreams', cursive;
    font-size: 30px;
  }

  #mid-creation, #mid-decks, #mid-colection {
    display: flex;
    justify-content: center;
    color: #F6E9EE;
    width: 100%;
    position: absolute;
    z-index: 0;
  }

  #mid-title{
    font-family: 'Broken Dreams', cursive;
    font-size: 30px;
    color: #F6E9EE;
  }


  #btns-creation, #btns-decks, #btns-colection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 20%;
    cursor: pointer;
    z-index: 1;
  }

  #btns-decks {
    justify-content: space-around;
    width: 15%;
  }

  #btns-colection {
    width: 10%;
  }

  #to-decks, #to-menu, #to-colection, #to-creation {
    background-color: transparent;
    color: #F6E9EE;
    border-style: none;
    width: fit-content;
    font-size: 30px;
    font-family: 'Broken Dreams', cursive;
    cursor: pointer;
  }

  #to-decks:hover,
  #to-colection:hover,
  #to-menu:hover,
  #to-creation:hover {
    background-color: transparent;
    color: #4B4A4B;
    border-style: none;
    transition: 0.5s;
  }
}

@media screen and (min-width: 800px) {
  .header {
    background-color: #F96565;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    width: 100%;
    position: fixed;
    top: 0;
  }
  #menu-header-title {
    /* Área do título do menu */
    display: flex;
    justify-content: center;
    color: #F6E9EE;
    width: 100%;
    position: absolute;
    z-index: 0;
  }

  #creation-header-title,
  #decks-header-title,
  #colection-header-title {
    padding-left: 10px;
  }

  #title {
    /* <p> com o texto do título */
    color: #F6E9EE;
    font-family: 'Broken Dreams', cursive;
    font-size: 30px;
  }

  #mid-creation, #mid-decks, #mid-colection {
    display: flex;
    justify-content: center;
    color: #F6E9EE;
    width: 100%;
    position: absolute;
    z-index: 0;
  }

  #mid-title{
    font-family: 'Broken Dreams', cursive;
    font-size: 30px;
    color: #F6E9EE;
  }


  #btns-creation, #btns-decks, #btns-colection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 20%;
    cursor: pointer;
    z-index: 1;
  }

  #btns-decks {
    justify-content: space-around;
    width: 15%;
  }

  #btns-colection {
    width: 10%;
  }

  #to-decks, #to-menu, #to-colection, #to-creation {
    background-color: transparent;
    color: #F6E9EE;
    border-style: none;
    width: fit-content;
    font-size: 30px;
    font-family: 'Broken Dreams', cursive;
    cursor: pointer;
  }

  #to-decks:hover,
  #to-colection:hover,
  #to-menu:hover,
  #to-creation:hover {
    background-color: transparent;
    color: #4B4A4B;
    border-style: none;
    transition: 0.5s;
  }

}