.css-i4bv87-MuiSvgIcon-root {
    color: #F6E9EE;
}

#button-long-menu {
    width: 30px;
    height: fit-content;
}
.long-menu-button {
    background-color: transparent;
    border: none;
    color: #F6E9EE;
    font-size: 20px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    letter-spacing: 0.1ch;
    font-family: 'Broken Dreams', arial;
}

.item-long-menu {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
}