@media screen and (max-width: 800px) {
.form-total {
  display: flex;
  justify-content: center;
  min-width: 380px;
}

.formulario {
  height: 450px;
  align-items: center;
  background-color: rgba(213, 224, 224, 0.747);
  border-radius: 1.0em;
  border: 0.1ch solid rgba(46, 43, 43, 0.281);
  color: #4B4A4B;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  padding-top: 20px;
  text-align: center;
  max-width: 360px;
  min-width: 340px;
  margin: 10px 0 10px 0;
}

#top-section-form {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.label-area {
  display: flex;
  align-items: flex-start;
  width: fit-content;
}

#custo-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100%;
}

#custo-input {
  width: 75%;
  height: 25px;
  background-color: transparent;
  outline-style: none;
  text-align: center;
  border: none;
  border:#a6a6a6 solid 0.3ch;
  border-radius: 3em;
  color: #4B4A4B;
  font-size: 16px;
}

#name-input {
  font-size: 15px;
  height: 45px;
  text-align: center;
  width: 67%;
  border-radius: 5em;
  background-color: transparent;
  outline-style: none;
  border: #a6a6a6 solid 0.3ch;
  color: #152222;
}

#rarity-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100%;
}

#select-form-rarity {
  font-size: 16px;
  height: 31px;
  text-align: center;
  width: 95%;
  border-radius: 5em;
  background-color: transparent;
  border: #a6a6a6 solid 0.3ch;
  color: #4B4A4B;
}

#img-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

#image-input {
  height: 30px;
  width: 300px;
  font-size: 16px;
  text-align: center;
  border-radius: 5em;
  background-color: transparent;
  outline-style: none;
  border: #a6a6a6 solid 0.3ch;
  color: #152222;
}

#upload-img{
  text-align: center;
  width: 300px;
  height: 22px;
  padding: 2px;
  outline-style: none;
  border-radius: 3em;
  border: #a6a6a6 solid 0.3ch;
  color: #152222;
}

#status-name {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 5px;
  color: #152222;
  width: 100%;
}

#title-status {
  width: 30%;
}

.number-area {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 80%;
}

#attr-area {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#attr1-area, #attr2-area, #attr3-area {
  border-radius: 3em;
  border-style: none;
  border:#a6a6a6 solid 0.3ch;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 25%;
}

.input-form-n {
  font-size: 18px;
  text-align: center;
  width: 30px;
  background-color: transparent;
  color: #4B4A4B;
  outline-style: none;
  border-style: none;
}

.input-form-n-max {
  font-size: 18px;
  text-align: center;
  width: 30px;
  background-color: transparent;
  color: #F96565;
  border-style: none;
  outline-style: none;
}

.input-form-max {
  font-size: 15px;
  height: 35px;
  text-align: center;
  width: 320px;
  border-radius: 3em;
  background-color: transparent;
  border-bottom: #8b0404 solid 0.3ch;
  outline-style: none;
  color: #4B4A4B;
  animation: treme 0.1s;
  animation-iteration-count: 3;
}

@keyframes treme {
  0% {margin-left: 0;}
  25% {margin-left: 5px;}
  50% {margin-left: 0;}
  75% {margin-left: -5px;}
  100% {margin-left: 0;}
}

#description-input {
  resize: none;
  font-size: 15px;
  height: 98px;
  text-align: center;
  width: 320px;
  border-radius: 1em;
  background-color: transparent;
  border: #a6a6a6 solid 0.3ch;
  outline-style: none;
  color: #152222;
}

#description-input:focus {
  border-radius: 1em;
  background-color: transparent;
  border: #F96565 solid 0.3ch;
  outline-style: none;
  color: #4B4A4B;
}

.texto-trunfo {
  width: 90px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.secao-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.input-check-trunfo {
  height: 22px;
  width: 30px;
}

#custo-input:focus,
#name-input:focus,
#image-input:focus,
#upload-img:hover,
#attr1-area:focus-within,
#attr2-area:focus-within,
#attr3-area:focus-within,
#select-form-raridade:focus {
  border-radius: 3em;
  border:#F96565 solid 0.3ch;
}

#checkbox-save {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  height: 25px;
}

#aviso-sp {
  width: 180px;
  color: #F96565;
  text-shadow: 0.5px 0.5px 0.5px #af6666;
}

#botao-salvar-off {
  color: #fff;
  font-size: 18px;
  height: 35px;
  width: 110px;
  border-style: none;
  border-radius: 0.5ch;
  background-color: #F96565;
}

#botao-salvar-on {
  color: #152222;
  font-size: 18px;
  height: 35px;
  width: 110px;
  border-style: none;
  border-radius: 0.5ch;
  background-color: #a6a6a6;
}

#botao-salvar-on:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
}

@media screen and (min-width: 800px) {
  .form-total {
    display: flex;
    justify-content: center;
    width: 55%;
    max-width: 450px;
  }
  
  .formulario {
    height: 435px;
    align-items: center;
    background-color: rgba(213, 224, 224, 0.747);
    border-radius: 1.0em;
    border: 0.1ch solid rgba(46, 43, 43, 0.281);
    color: #4B4A4B;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    padding-top: 20px;
    text-align: center;
    width: 100%;
  }
  
  #top-section-form {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .label-area {
    display: flex;
    align-items: flex-start;
    width: fit-content;
  }
  
  #custo-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: 100%;
  }
  
  #custo-input {
    width: 75%;
    height: 25px;
    background-color: transparent;
    outline-style: none;
    text-align: center;
    border: none;
    border:#a6a6a6 solid 0.3ch;
    border-radius: 3em;
    color: #4B4A4B;
    font-size: 16px;
  }
  
  #name-input {
    font-size: 15px;
    height: 45px;
    text-align: center;
    width: 67%;
    border-radius: 5em;
    background-color: transparent;
    outline-style: none;
    border: #a6a6a6 solid 0.3ch;
    color: #152222;
  }
  
  #rarity-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: 100%;
  }
  
  #select-form-rarity {
    font-size: 16px;
    height: 31px;
    text-align: center;
    width: 95%;
    border-radius: 5em;
    background-color: transparent;
    border: #a6a6a6 solid 0.3ch;
    color: #4B4A4B;
  }
  
  #img-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  
  #image-input {
    height: 30px;
    width: 300px;
    font-size: 16px;
    text-align: center;
    border-radius: 5em;
    background-color: transparent;
    outline-style: none;
    border: #a6a6a6 solid 0.3ch;
    color: #152222;
  }
  
  #upload-img{
    text-align: center;
    width: 300px;
    height: 22px;
    padding: 2px;
    outline-style: none;
    border-radius: 3em;
    border: #a6a6a6 solid 0.3ch;
    color: #152222;
  }
  
  #status-name {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 5px;
    color: #152222;
    width: 100%;
  }
  
  #title-status {
    width: 30%;
  }
  
  .number-area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
  }
  
  #attr-area {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  #attr1-area, #attr2-area, #attr3-area {
    border-radius: 3em;
    border-style: none;
    border:#a6a6a6 solid 0.3ch;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 25%;
  }
  
  .input-form-n {
    font-size: 18px;
    text-align: center;
    width: 30px;
    background-color: transparent;
    color: #4B4A4B;
    outline-style: none;
    border-style: none;
  }
  
  .input-form-n-max {
    font-size: 18px;
    text-align: center;
    width: 30px;
    background-color: transparent;
    color: #F96565;
    border-style: none;
    outline-style: none;
  }
  
  .input-form-max {
    font-size: 15px;
    height: 35px;
    text-align: center;
    width: 320px;
    border-radius: 3em;
    background-color: transparent;
    border-bottom: #8b0404 solid 0.3ch;
    outline-style: none;
    color: #4B4A4B;
    animation: treme 0.1s;
    animation-iteration-count: 3;
  }
  
  @keyframes treme {
    0% {margin-left: 0;}
    25% {margin-left: 5px;}
    50% {margin-left: 0;}
    75% {margin-left: -5px;}
    100% {margin-left: 0;}
  }
  
  #description-input {
    resize: none;
    font-size: 15px;
    height: 98px;
    text-align: center;
    width: 320px;
    border-radius: 1em;
    background-color: transparent;
    border: #a6a6a6 solid 0.3ch;
    outline-style: none;
    color: #152222;
  }
  
  #description-input:focus {
    border-radius: 1em;
    background-color: transparent;
    border: #F96565 solid 0.3ch;
    outline-style: none;
    color: #4B4A4B;
  }
  
  .texto-trunfo {
    width: 90px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .secao-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
  }
  
  .input-check-trunfo {
    height: 22px;
    width: 30px;
  }
  
  #custo-input:focus,
  #name-input:focus,
  #image-input:focus,
  #upload-img:hover,
  #attr1-area:focus-within,
  #attr2-area:focus-within,
  #attr3-area:focus-within,
  #select-form-raridade:focus {
    border-radius: 3em;
    border:#F96565 solid 0.3ch;
  }
  
  #checkbox-save {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    height: 25px;
  }
  
  #aviso-sp {
    width: 180px;
    color: #F96565;
    text-shadow: 0.5px 0.5px 0.5px #af6666;
  }
  
  #botao-salvar-off {
    color: #fff;
    font-size: 18px;
    height: 35px;
    width: 110px;
    border-style: none;
    border-radius: 0.5ch;
    background-color: #F96565;
  }
  
  #botao-salvar-on {
    color: #152222;
    font-size: 18px;
    height: 35px;
    width: 110px;
    border-style: none;
    border-radius: 0.5ch;
    background-color: #a6a6a6;
  }
  
  #botao-salvar-on:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
  }