@media screen and (max-width: 800px) {
    .css-1w12ozd {
        height: 75%;
        width: 60%;
    }

    #modal {
        height: 80%;
        width: 80%;
    }

    #modal-top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    color: #4B4A4B;
    }

    #modal-button, #btn-close {
        background-color: #f96565;
        border: none;
        color: #F6E9EE;
    }

    #modal-button {
        width: 100%;
    }

    #btn-close {
        height: 20px;
        width: 20px;
    }

    #modal-intern {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    #modal-modal-description {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        height: 95%;
        width: 100%;
        overflow-y: scroll;
    }

    #grid-card-small {
        color: #4B4A4B;
        background-color: rgba(213, 224, 224, 0.644);
        align-items: center;
        justify-content: space-between;
        text-align: center;
        display: flex;
        flex-direction: column;
        border: 0.1ch solid rgba(46, 43, 43, 0.281);
        box-shadow: 1px 2px rgba(46, 43, 43, 0.281);
        padding: 10px 3px 5px 3px;
        margin: 5px;
        height: 55%;
        width: 230px;
        border-radius: 0.5em;
        font-size: 15px;
    }

    #empty {
        color: #696869;
    }

    #top-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 9%;
        width: 95%;
    }

    #grid-card-price {
        align-items: last baseline;
        background-image: url('../Images/custo.png');
        background-size: cover;
        background-position: 50%;
        color: #696869;
        font-weight: 600;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 15%;
    }

    #grid-card-rarity {
        height: 100%;
    }

    #grid-card-image {
        margin: 5px 0 5px 0;
        height: 140px;
        width: 110px;
        border-radius: 0.5em;
    }

    #attributes {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 95%;
    }

    #attribute-life,
    #attribute-strength,
    #attribute-defense {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        width: 25%;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #grid-description {
        resize: none;
        text-align: center;
        background-color: transparent;
        border: none;
        color: #4B4A4B;
        font-size: 14px;
        height: 35%;
        width: 90%;
    }

    #description-line {
        margin-bottom: 10px;
        width: 80%;
    }
}

@media screen and (min-width: 800px) {

    .css-1w12ozd {
        height: 50%;
        width: 50%;
    }

    #modal {
        height: fit-content;
        width: 80%;
    }

    #modal-top {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        color: #4B4A4B;
    }

    #modal-button, #btn-close {
        background-color: #f96565;
        border: none;
        color: #F6E9EE;
        cursor: pointer;
    }

    #modal-button {
        width: fit-content;
    }

    #btn-close {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    #modal-intern {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    #modal-modal-description {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-around;
        height: 90%;
        width: 100%;
        overflow-y: scroll;
        border: solid 0.1ch rgb(30, 175, 168);
        border-radius: 1em;
    }

    #grid-card-small {
        color: #4B4A4B;
        background-color: rgba(213, 224, 224, 0.644);
        align-items: center;
        justify-content: space-between;
        text-align: center;
        display: flex;
        flex-direction: column;
        border: 0.1ch solid rgba(46, 43, 43, 0.281);
        box-shadow: 1px 2px rgba(46, 43, 43, 0.281);
        padding: 10px 3px 5px 3px;
        margin: 5px;
        height: 75%;
        width: 230px;
        border-radius: 0.5em;
        font-size: 15px;
    }

    #empty {
        color: #696869;
    }

    #top-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 9%;
        width: 95%;
    }

    #grid-card-price {
        align-items: last baseline;
        background-image: url('../Images/custo.png');
        background-size: cover;
        background-position: 50%;
        color: #696869;
        font-weight: 600;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 15%;
    }

    #grid-card-rarity {
        height: 100%;
    }

    #grid-card-image {
        margin: 5px 0 5px 0;
        height: 140px;
        width: 110px;
        border-radius: 0.5em;
    }

    #attributes {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 95%;
    }

    #attribute-life,
    #attribute-strength,
    #attribute-defense {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        width: 25%;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #grid-description {
        resize: none;
        text-align: center;
        background-color: transparent;
        border: none;
        color: #4B4A4B;
        font-size: 14px;
        height: 35%;
        width: 90%;
    }

    #description-line {
        margin-bottom: 10px;
        width: 80%;
    }
}