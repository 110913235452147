@media screen and (max-width: 800px) {
#card-preview-area {
  max-width: 360px;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

#card-preview {
  background-color: rgba(213, 224, 224, 0.644);
  border: 0.1ch solid rgba(46, 43, 43, 0.281);
  border-radius: 1.0em;
  font-family: sans-serif;
  height: 450px;
  margin: 0;
  width: 97%;
  padding: 10px 5px 5px 5px;
  margin: 10px;
}

#card-super-preview {
  background-color: rgba(213, 224, 224, 0.644);
  border: 0.3ch solid #f96565;
  border-radius: 1.0em;
  font-family: sans-serif;
  height: 450px;
  margin: 0;
  width: 97%;
  padding: 10px;
}

.card-top-preview {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 10%;
  color: #4B4A4B;
  font-size: 17px;
  width: 100%;
}

#card-preview-text {
  font-size: 18px;
  width: 100px;
  margin-bottom: 10px;
  border: solid rgb(50, 53, 50) 0.1ch;
}

#card-price {
  background-image: url('../Images/custo.png');
  background-size: cover;
  background-position: 50%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: last baseline;
  width: 11%;
  color: #696869;
}

#card-name {
  text-align: center;
  margin: 0 2px 0 2px;
  width: 83%;
}

#rarity-card {
  height: 70%;
  width: 10%;
}

.status {
  color: #152222;
  font-size: 18px;
  font-weight: 400;
  line-height: 15px;
}

#trunfo-card-text {
  color: #f96565;
  font-weight: 600;
  font-size: 20px;
  padding-right: 5px;
}

#logo-card-text {
  color: #4B4A4B;
  font-weight: 600;
  font-size: 18px;
  padding-right: 5px;
}

#card-img-attr {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}

#card-image {
  background-color: transparent;
  outline: none;
}

.card-secao-attr {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

#card-attr1,
#card-attr2,
#card-attr3 {
  border-radius: 3em;
  border-style: none;
  border: #7D7C7C solid 0.2ch;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 25%;
  font-size: 15px;
  font-weight: 400;
  padding: 2px;
  margin-top: 15px;
  margin-bottom: 10px;
}

#card-description-preview,
#card-description-preview {
  border-style: none;
  height: 120px;
  width: 308px;
  padding: 20px;
  line-height: 20px;
  background-color: transparent;
  text-align: center;
  font-size: 15px;
  resize: none;
  color: #152222;
}
}

@media screen and (min-width: 800px) {
  #card-preview-area {
    min-width: 340px;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #card-preview {
    background-color: rgba(213, 224, 224, 0.644);
    border: 0.1ch solid rgba(46, 43, 43, 0.281);
    border-radius: 1.0em;
    font-family: sans-serif;
    height: 450px;
    margin: 0;
    width: 90%;
    padding: 10px 5px 5px 5px;
    margin: 10px;
  }
  
  #card-super-preview {
    background-color: rgba(213, 224, 224, 0.644);
    border: 0.3ch solid #f96565;
    border-radius: 1.0em;
    font-family: sans-serif;
    height: 450px;
    margin: 0;
    width: 90%;
    padding: 10px;
  }
  
  .card-top-preview {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 10%;
    color: #4B4A4B;
    font-size: 17px;
    width: 100%;
  }
  
  #card-preview-text {
    font-size: 18px;
    width: 100px;
    margin-bottom: 10px;
    border: solid rgb(50, 53, 50) 0.1ch;
  }
  
  #card-price {
    background-image: url('../Images/custo.png');
    background-size: cover;
    background-position: 50%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: last baseline;
    width: 11%;
    color: #696869;
  }
  
  #card-name {
    text-align: center;
    margin: 0 2px 0 2px;
    width: 83%;
  }
  
  #rarity-card {
    height: 70%;
    width: 10%;
  }
  
  .status {
    color: #152222;
    font-size: 18px;
    font-weight: 400;
    line-height: 15px;
  }
  
  #trunfo-card-text {
    color: #f96565;
    font-weight: 600;
    font-size: 20px;
    padding-right: 5px;
  }
  
  #logo-card-text {
    color: #4B4A4B;
    font-weight: 600;
    font-size: 18px;
    padding-right: 5px;
  }
  
  #card-img-attr {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
  }
  
  #card-image {
    background-color: transparent;
    outline: none;
  }
  
  .card-secao-attr {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  
  #card-attr1,
  #card-attr2,
  #card-attr3 {
    border-radius: 3em;
    border-style: none;
    border: #7D7C7C solid 0.2ch;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 25%;
    font-size: 15px;
    font-weight: 400;
    padding: 2px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  #card-description-preview,
  #card-description-preview {
    border-style: none;
    height: 120px;
    width: 308px;
    padding: 20px;
    line-height: 20px;
    background-color: transparent;
    text-align: center;
    font-size: 15px;
    resize: none;
    color: #152222;
  }
  }